body {
    background-color: $BodyColor;
    font-family: "Work Sans";
    color: $PrimaryText;
    letter-spacing: 0.02em;
    -webkit-overflow-scrolling: auto;
    overscroll-behavior: auto;
    overflow: auto;
}
.page-content {
    min-height: 50vh;
    padding: 0 0 40px 0px;
    margin-top: 80px;
    box-sizing: border-box;
    // padding: toRem(16) toRem(40);
    // text-align: -webkit-center;
    width: 100%;
    @media screen and (min-width: 1200px) {
        padding: 0 100px 80px 100px;
    }
    @media screen and (max-width: 1200px) {
        padding: 0 40px 40px 40px;
    }
    @media screen and (max-width: 600px) {
        margin-top: 0px;
        padding: 0;
    }
}
.profilePage {
    display: flex;
    justify-content: center;
}
.swal2-container {
    z-index: 1600 !important;
}
.swal2-popup {
    padding: 48px !important;
    width: 36em !important;
}
 
a {
    color: #f5490f;
    &:hover {
        color: #808080;
    }
}
 
.swal2-title {
    font-family: GRIFTER;
}
 
// .swal2-title {
//     font-family: GRIFTER;
// }
 
.swal2-content {
    font-family: Work Sans;
    font-size: 14px !important;
}
.swal2-title {
    font-family: MonoSpec;
    font-size: 18px !important;
    color: #010101 !important;
    margin-bottom: 16px !important;
}
.swal2-confirm {
    font-family: MonoSpec;
    background-color: #f5490f !important;
    min-width: 120px;
    border-radius: 80px !important;
    height: 48px;
}
.swal2-cancel {
    font-family: MonoSpec;
    background-color: #d9d9d9 !important;
    min-width: 120px;
    border-radius: 80px !important;
    height: 48px;
    color: #010101 !important;
}
.swal2-deny {
    font-family: MonoSpec;
    background-color: #010101 !important;
    min-width: 120px;
    border-radius: 80px !important;
    height: 48px;
}
.swal2-popup {
    padding: 2.25em;
}
.swal2-icon.swal2-success {
    border-color: #f5490f;
    color: #f5490f;
}
.swal2-icon.swal2-info {
    border-color: #f5490f !important;
    color: #f5490f !important;
    // border-color: #33b5e5 !important;
    // color: #33b5e5 !important;
}
.titleCard {
    font-family: "GRIFTER";
    font-weight: 700;
    font-size: toRem(18);
    line-height: toRem(28);
    letter-spacing: 0.02em;
    color: $PrimaryText;
}
.CardGridCustom {
    background-color: $ContentColor;
    border-radius: toRem(16);
    padding: toRem(24) toRem(24);
    box-sizing: border-box;
}
#CollaspeMenuWrapperId {
    top: 76px;
}