@import "styles/scss/variable.scss";
@import "styles/scss/fontSizeByRem.scss";

.ProfileCardWrapper {
    font-size: 14px;
    // max-width: 17.3rem;
    // min-height: calc(100vh);
    max-width: 17.3rem;
    padding: toRem(24) toRem(16);
    background-color: $ContentColor;
    border-radius: 1.14rem;
    text-align: center;
    position: relative;

    :global(.verticalTabs) {
        :global(.MuiTabs-flexContainer) {
            gap: 2rem;
        }
    }

    .avatarWrapper {
        display: inline-block;
        text-align: center;
        cursor: pointer !important;

        :global(.upload-button) {
            cursor: default !important;
        }

        :global(.icon-container) {
            display: none !important;
        }

        :global(.upload-avatar-component) {
            :global(.Mui-Stack-Wrapper) {
                display: block;
                width: fit-content;

                :global(.upload-avatar-component__name) {
                    position: static;
                    width: 100%;
                    height: auto;
                    margin-left: 0;
                    margin-top: toRem(24);

                    :global(.upload-avatar-component__name__main) {
                        width: 100%;
                        position: static;
                        transform: unset;
                        text-transform: uppercase;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        h1 {
                            font-family: "GRIFTER";
                            font-weight: 700;
                            font-size: toRem(20);
                            line-height: 25px;
                            letter-spacing: 0.02em;
                            word-break: break-all;
                            overflow: unset;
                            display: block;
                            @media screen and (max-width: 600px) {
                                font-size: toRem(12) !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .unitGroupWrapper {
        // height: 3.086rem;
        align-items: center;
        margin: 1.93rem 0;
    }

    .levelGroupWrapper {
        display: flex;
        gap: toRem(0);
        position: relative;
        justify-content: center;
        align-items: center;
        margin: 1.93rem 0;
        background: #161616;
        border-radius: toRem(8);
        height: toRem(40);
        .name {
            width: auto;
            // background: #161616;
            // border-radius: toRem(8);
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Work Sans";
            font-weight: 600;
            font-size: 1.142857rem;
            line-height: 1.142857rem;
            color: white;
            text-transform: uppercase;
        }

        .medal {
            :global(.MuiSvgIcon-root) {
                width: 3.5714rem;
                height: 3.5714rem;
                fill: #fdd831;
                position: absolute;
                transform: rotate(30deg);
            }

            img {
                position: absolute;
                width: toRem(30);
                height: toRem(30);
                right: toRem(5);
                bottom: toRem(5);
            }
        }
    }

    .coinWrapper {
        border: 1px solid #d1d1d1;
        border-radius: 0.8571rem;
        padding: toRem(24) toRem(9);

        .name {
            font-family: "GRIFTER";
            font-style: normal;
            font-weight: 700;
            font-size: 1.143rem;
            line-height: 1.214rem;
        }

        .point {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: toRem(8);
            margin-top: toRem(12);

            img {
                width: toRem(28);
                height: toRem(28);
                position: relative;
                top: toRem(1);
            }

            .pointText {
                font-family: "MonoSpec";
                font-style: normal;
                font-weight: 500;
                // font-size: toRem(32);
                // line-height: toRem(40);
                // letter-spacing: 0.01em;
                text-transform: uppercase;
                color: $color-primary-red;
                width: fit-content;
                height: fit-content;
            }
        }
    }

    .menuTabWrapper {
        margin-top: 2.2857rem;
    }

    @media screen and (max-width: $screen-sm-workout-max) {
        max-width: toRem(425);
        border-radius: 0;
        box-shadow: none;
        position: relative;
        height: toRem(245);

        .avatarWrapper {
            width: toRem(140);
            height: toRem(140);
            position: absolute;
            left: toRem(10);
            box-sizing: border-box;

            :global(.user-avatar-container) {
                width: 100% !important;
                height: 100% !important;
            }

            :global(.upload-avatar-component__name) {
                position: absolute !important;
                top: toRem(30);
                margin-top: 0 !important;
                right: toRem(-243);
                width: toRem(210) !important;

                @media screen and (max-width: 1250px) {
                    display: none !important;
                }

                // background-color: greenyellow;
                :global(.upload-avatar-component__name__main) {
                    width: fit-content !important;

                    @media screen and (min-width: 500px) {
                        width: 100% !important;
                    }
                }
            }
        }

        .userNameWrapper {
            position: absolute;
            width: toRem(210);
            box-sizing: border-box;
            right: toRem(20);
            font-family: "GRIFTER";
            font-style: normal;
            font-weight: 700;
            font-size: toRem(20);
            line-height: toRem(20);
            letter-spacing: 0.01em;
            text-transform: uppercase;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            top: toRem(24);
            padding: toRem(5) 0;
            @media screen and (max-width: 600px) {
                font-size: toRem(12);
            }
        }
        .longTextUsername {
            font-size: toRem(12);
            font-size: normal;
        }

        .levelGroupWrapper,
        .coinWrapper {
            width: toRem(210);
            box-sizing: border-box;
            position: absolute;
            right: toRem(20);
        }

        .levelGroupWrapper {
            bottom: toRem(140);
        }

        .coinWrapper {
            bottom: toRem(90);
            padding: toRem(8) toRem(8);

            .point {
                gap: toRem(8);
                margin-top: toRem(3);

                img {
                    width: toRem(24);
                    height: toRem(24);
                }

                .pointText {
                    font-size: toRem(16);
                    line-height: toRem(20);
                    font-weight: 500;
                }
            }
        }

        .menuTabWrapper {
            position: absolute;
            bottom: toRem(16);
            left: 0;
        }
    }

    @media screen and (max-width: 600px) {
        padding: toRem(0) toRem(16) toRem(24) toRem(16);

        .menuTabWrapper {
            max-width: 100%;
        }
    }
}