.membership_offer_modal {
    position: relative;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: white;
    border-radius: 16px;
    overflow: hidden;
    .membership_offer_modal-header {
        text-align: right;
    }

    .membership_offer_modal-content {
        width: 800px;
        border-radius: 5px;
        padding: 12px 0 48px 32px;
        border: none;
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .membership_offer-title {
                font-family: GRIFTER;
                font-size: 24px;
                font-weight: 700;
                line-height: 24.48px;
                letter-spacing: 0.01em;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #161616;
                margin: 24px 0;
                .highlight {
                    font-family: GRIFTER;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 24.48px;
                    letter-spacing: 0.01em;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #ff4106;
                    text-transform: uppercase;
                }
            }
            .membership_offer-description {
                font-family: GRIFTER;
                font-size: 56px;
                font-weight: 700;
                line-height: 57.12px;
                letter-spacing: 0.01em;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #ff4106;
                margin: 0;
            }
        }
        .content {
            padding-right: 32px;
            margin-top: 56px;
            .membership_offer-data {
                border: 1px solid #f9926f;
                background-color: #fff8f2;
                border-radius: 8px;
                padding: 32px 24px;
                display: flex;
                justify-content: space-between;
                position: relative;
                .currency {
                    position: absolute;
                    background-color: white;
                    border: 1px solid #d1d1d1;
                    padding: 6px 16px;
                    border-radius: 30px;
                    right: 25px;
                    top: -16px;
                }
                .data-tag {
                    background-color: #ff4106;
                    color: #fff;
                    border-radius: 30px;
                    padding: 6px 8px;
                    display: inline-block;
                    margin: 0;
                    margin-bottom: 12px;
                    text-transform: uppercase;
                    font-weight: 600;
                }
                .data-title {
                    //styleName: Title/1;
                    font-family: Work Sans;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    margin-bottom: 8px;
                    margin-top: 0;
                }
                .data-description {
                    //styleName: Body/2;
                    font-family: Work Sans;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    margin: 0;
                }
                .price_original {
                    font-family: MonoSpec;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 29.98px;
                    text-align: left;
                    text-decoration-line: line-through;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #ff4106;
                    margin: 0;
                    margin-bottom: 8px;
                    font-weight: bold;
                    text-align: right;
                }
                .price_discount {
                    //styleName: Button And Number/2;
                    font-family: MonoSpec;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 29.98px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    margin: 0;
                    text-align: right;
                    .unit {
                        //styleName: Button And Number/3;
                        font-family: MonoSpec;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.01em;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        margin: 0;
                    }
                }
            }
            .membership_offer_more-info {
                padding-left: 24px;
                margin-top: 32px;
                .membership_offer_more-info-item {
                    //styleName: Body/2;
                    font-family: Work Sans;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                }
            }
            .membership_offer-button {
                .btn-claim-offer {
                    border: 2px solid #161616;
                    box-shadow: 4px 4px 0px 0px #161616 !important;
                    padding: 12px 70px;
                    &:hover {
                        box-shadow: none !important;
                    }
                }
                text-align: center;
                margin-top: 56px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .membership_offer_modal {
        .membership_offer_modal-content {
            padding: 12px 0 32px 20px;
            width: 740px;
            .header {
                // .membership_offer-image {
                //     width: 316px;
                //     height: 216px;
                //     object-fit: contain;
                // }
                .membership_offer-title {
                    font-size: 20px;
                    line-height: 20.48px;
                    margin: 20px 0;
                    .highlight {
                        font-size: 20px;
                        line-height: 20.48px;
                    }
                }
                .membership_offer-description {
                    font-size: 46px;
                    line-height: 47.12px;
                }
            }
            .content {
                padding-right: 20px;
                margin-top: 30px;
                .membership_offer-data {
                    padding: 24px 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .membership_offer_modal {
        width: 94%;
        overflow: hidden;
        .membership_offer_modal-content {
            width: 100%;

            .header {
                .membership_offer-image {
                    width: 280px;
                    height: 180px;
                    object-fit: contain;
                }
                .membership_offer-title {
                    font-size: 18px;
                    line-height: 18.48px;
                    margin: 18px 0;
                    .highlight {
                        font-size: 18px;
                        line-height: 18.48px;
                    }
                }
                .membership_offer-description {
                    font-size: 32px;
                    line-height: 32.64px;
                }
            }
            .content {
                margin-right: 20px;
                margin-top: 40px;
                .membership_offer-data {
                    padding: 16px 12px;
                    align-items: center;

                    .data-title {
                        font-size: 16px;
                        line-height: 20px;
                    }
                    .data-description {
                        font-size: 14px;
                        line-height: 16px;
                    }
                    .price_original {
                        font-size: 20px;
                        line-height: 24px;
                    }
                    .price_discount {
                        font-size: 20px;
                        line-height: 24px;

                        .unit {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                }

                .membership_offer-button {
                    text-align: center;
                    width: 60%;
                    margin: 40px auto 0;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .membership_offer_modal {
        width: 96%;

        .membership_offer_modal-content {
            padding-left: 16px;
            .header {
                .membership_offer-image {
                    width: 190px;
                    height: 140px;
                    object-fit: contain;
                }
                .membership_offer-title {
                    font-size: 16px;
                    line-height: 17.6px;
                    .highlight {
                        font-size: 16px;
                        line-height: 17.6px;
                        display: block;
                        margin-top: 4px;
                    }
                }
            }
            .content {
                .membership_offer-data {
                    padding: 12px;
                    flex-direction: column;
                    align-items: flex-start;
                    background-color: #fff8f2;
                    .currency {
                        padding: 6px 10px;
                        right: 6px;
                        top: 80%;
                    }
                    .data-tag {
                        width: 100%;
                    }
                    .price_original {
                        text-align: left;
                        margin-top: 24px;
                    }
                }
                .membership_offer_more-info {
                    margin-top: 24px;
                    .membership_offer_more-info-item {
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
                .membership_offer-button {
                    margin-top: 24px;
                    width: 80%;

                    .btn-claim-offer {
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .membership_offer_modal {
        .membership_offer_modal-content {
            .header {
                .membership_offer-image {
                    width: 160px;
                    height: 130px;
                    object-fit: contain;
                }
            }
            .content {
                margin-right: 16px;
                padding-right: 16px;
                margin-top: 5px;
                .membership_offer_more-info {
                    margin-top: 5px;
                    padding-left: 20px;
                   
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .membership_offer_modal {
        .membership_offer_modal-content {
            padding-top: 0;
            max-height: 84vh;
            overflow: auto;
            .header {
                .membership_offer-image {
                    width: 150px;
                    height: 130px;
                    object-fit: contain;
                }
            }
            .content {
                margin-top: 5px;
                .membership_offer-data {
                    .price_original {
                        margin-top: 5px;
                    }
                }
                .membership_offer_more-info {
                    margin-top: 5px;
                    padding-left: 20px;
                    
                }
                
            }
        }
    }
}
